import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/krabickova-dieta-usetri-cas-i-penize.jpg'
import styles from './post-grid.module.css'

const KrabickovaDietaUsetriCasPenize = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Jak krabičková dieta ušetří čas i peníze | NutritionPro'}
        description={
          'Krabičková dieta vám může ušetřit mnoho času i peněz, a my v tomto článku probereme, proč tomu tak je.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/krabickova-dieta-usetri-cas-i-penize">
            Jak krabičková dieta ušetří čas i peníze
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Jak krabičková dieta ušetří čas i peníze"
              date="17.04.2023"
            />
            <div>
              <p className={styles.postText}>
                Krabičková dieta vám může ušetřit mnoho času i peněz, a my v
                tomto článku probereme, proč tomu tak je.
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />

              <h5 className={styles.postTitle}>Časová úspora</h5>
              <p className={styles.postText}>
                Jedním z hlavních důvodů, proč je{' '}
                <b>
                  dobré využívat{' '}
                  <a href="https://nutritionpro.cz/" target="_blank">
                    krabičkovou dietu
                  </a>
                </b>
                , je <b>obrovská časová úspora</b>. V dnešní uspěchané době není
                vždy snadné si najít čas na přípravu vyvážených jídel. Nehledě
                na vymýšlení receptů a nákupy ingrediencí.
              </p>

              <p className={styles.postText}>
                <b>Stravování je důležitou součástí dne</b> každého z nás. Měli
                bychom jíst zhruba 3-5x denně, což mnoho z nás <b>šidí</b>,
                stejně tak jako svůj optimální energetický příjem. Důvodem je{' '}
                <b>nedostatek času, mnoho práce nebo nechuť </b> vymýšlet pořád
                dokola nové pokrmy. Zanedbávání jídla, díky kterému máme{' '}
                <b>dostatek energie a živin</b> pro fungování našeho těla, se z
                dlouhodobého hlediska rozhodně nevyplatí.
              </p>

              <p className={styles.postText}>
                Pokud se snažíte <b>zhubnout</b>, je dodržování optimálního
                energetického příjmu <b>zásadní</b>. Problémem totiž není jen
                nadbytečný příjem, ale také příjem <b>nedostatečný</b>. Vaše
                tělo potřebuje mít <b>jistotu, že dostane dostatek živin</b>,
                aby mohlo správně fungovat. Pokud se nesnažíte zhubnout, ale{' '}
                <b>nabrat svalovou hmotu</b>, je pro vás dostatek jídla též
                zásadní. Bez dostatečné porce bílkovin (a ostatních živin) to
                určitě nepůjde.
              </p>

              <p className={styles.postText}>
                Je to pochopitelné. <b>Času je málo, ale povinností hodně.</b>{' '}
                Práce, cvičení, domácnost, zábava…a do toho každý den neustále
                vymýšlet, nakupovat a vařit. To je přesně ten důvod, proč jsou{' '}
                <b>krabičky perfektním řešením</b>. S{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  krabičkami Nutritionpro
                </a>{' '}
                vás totiž čeká <b>dostatek kvalitního jídla</b>, ale i velká{' '}
                <b>pohoda</b>. Jídlo je uvařeno a doručeno až k vašim dveřím
                (pokud si ho sami nechcete vyzvednout). Můžete si vybrat{' '}
                <b>z několika programů</b> podle toho, jaký je váš cíl a toho,
                kolikrát denně chcete jíst. Pak už stačí jen v klidu vyčkat, až
                vám jídlo dovezeme. Díky <b>ušetřeným hodinám</b> budete mít čas
                na všechno, co potřebujete nebo chcete dělat.{' '}
                <b>O jídlo se za vás postaráme my.</b>
              </p>

              <h5 className={styles.postTitle}>Finanční úspora</h5>
              <p className={styles.postText}>
                Mnoho lidí se domnívá, že je krabičková dieta drahá. Když ale
                vezmete v úvahu, kolik <b>běžně utrácíte za nákup</b> potravin,
                jak dlouho vám nákup a vymýšlení jídla zabere a také jakou dobu
                strávíte v kuchyni, cena se ihned jeví jako{' '}
                <b>velmi přijatelná</b>.
              </p>

              <p className={styles.postText}>
                Další věcí je to, kolikrát týdně (nebo dokonce denně) jste mimo
                domov nebo nemáte čas a rozhodnete se něco pořídit{' '}
                <b>rychle po cestě</b> nebo <b>zajít do restaurace</b>. Schválně
                se zamyslete nad tím, <b>jak často si kupujete hotová jídla</b>.
                A také nad tím, kde si je kupujete. Ve výsledku tak můžete za
                jídlo zaplatit mnohem více peněz. A máte vždy{' '}
                <b>jistotu, že se jedná o kvalitní potraviny</b>?
              </p>

              <p className={styles.postText}>
                Finanční úspora jde ruku v ruce s tou časovou. Pokud{' '}
                <b>nemáte čas, chodíte</b> místo vaření <b>do restaurací</b>{' '}
                nebo <b>fastfoodů</b>, tím pádem i mnohem <b>víc utratíte</b>. V
                horším případě nejíte, což se může podepsat na tom
                nejdůležitějším - zdraví. Další věcí je <b>kontrola</b> nad tím,
                co jíte. Tu při kupování hotových jídel mít nemůžete. S{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  Nutritionpro
                </a>{' '}
                máte jistotu, že jíte kvalitní jídlo a také přesně{' '}
                <b>víte, kolik za něj utratíte</b>. Vše budete mít pod
                kontrolou.
              </p>

              <p className={styles.postText}>
                Krabičková dieta je velmi užitečná pro všechny, kteří by{' '}
                <b>
                  svůj čas rádi věnovali jiným činnostem, než je příprava jídla
                </b>{' '}
                a zároveň nechtějí každý den <b>vyhazovat peníze</b> za jídlo
                neznámého původu v restauracích. Pokud chcete mít{' '}
                <b>kontrolu nad svým časem, jídlem i financemi</b>, zvažte{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  naše krabičky!
                </a>
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default KrabickovaDietaUsetriCasPenize
